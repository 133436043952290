import React from "react"
import { graphql } from 'gatsby';

import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'

export default ({ data, location }) => (
  <Layout>
    <SEO
      pagetitle="お問い合わせ"
      pagedesc="弊社へのお問い合わせしたい場合は、こちらからお願いいたします。"
      pagepath={location.pathname}
    />
    <main className="l-content">
      <div className="p-content">
        <div className="m-mv mv">
          <div className="mv__img-wrap">
            <Img fluid={data.key01.childImageSharp.fluid} alt="メインビジュアル画像" style={{ height: "100%", width: "100%" }} />
            <h2 className="mv__hdg">お問い合わせ</h2>
          </div>
        </div>
        <div className="p-head head l-wrap">
          <h3 className="head__hdg--01">contact</h3>
          <h4 className="head__desc--01">お問い合わせ</h4>
        </div>

        <div className="p-contact contact">
          <div className="contact__inner l-wrap">
            <form
              name="contact"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              action="/thanks/"
            >
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="bot-field" />

              <div className="form-group">
                <label>お名前<abbr title="required" className="contact__required">（必須）</abbr>
                <input type="text" className="form-control contact__input" id="name" name="name" placeholder="お名前を入力してください。" maxlength="30" minlength="2" required autocomplete="name" />
                </label>
              </div>
              <div className="form-group">
                <label>メールアドレス<abbr title="required" className="contact__required">（必須）</abbr>
                <input type="email" className="form-control contact__email" id="email" name="email" placeholder="メールアドレスを入力してください。" pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" required autocomplete="email" />
                </label>
              </div>
              <div className="form-group">
                <label>お問い合わせ内容<abbr title="required" className="contact__required">（必須）</abbr>
                <textarea className="form-control contact__textarea" id="contact" name="content" rows="8" required></textarea>
                </label>
              </div>

              <div className="form-group">
              <button type="submit" className="contact__send">送信</button>
              </div>
            </form>

          </div>
        </div>
      </div>{/* p-content */}
    </main>
  </Layout>
)

export const query = graphql`
  query {
    key01: file(relativePath: {eq: "solutions_mv01.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`